import React from "react";
import loader from "./assets/loader.gif";
import { putDayComment } from "./Endpoints";
import SingleComment from "./SingleComment";

export default class CommentSection extends React.Component {

  state = {
    isEditing: false,
    isLoading: false,
    comment: ""
  };

  setEditing = (state) => {
    this.setState({ isEditing: true });
  };

  onCommentChange = (changeEvent) => {
    this.setState({ comment: changeEvent.target.value });
  };

  submitComment = async () => {
    this.setState({ isLoading: true });
    let { day, isOperator } = this.props;
    let { comment } = this.state;
    let author = isOperator ? "Operator" : "Client";
    let success = await putDayComment(day.dynamoDBItem, comment, author);
    if (success) {
      this.props.refresh();
      this.setState({ isLoading: false, comment: "", isEditing: false });
    } else {
      this.setState({ isLoading: false });
    }
  };

  render() {
    let { day, operator, client } = this.props;
    let { isEditing, isLoading, comment } = this.state;
    let comments = day.data.comments.map(c => <SingleComment operator={operator} client={client} comment={c} />);
    if (isLoading) {
      return <div>
        {comments}
        <div className='day-content-subsection bordered'>
          <div className='loading'><img src={loader} alt='Loading...' /></div>
        </div>
      </div>;
    } else if (isEditing) {
      return <div>
        {comments}
        <div className='day-content-subsection bordered'>
        <textarea
          className='comment-area'
          value={comment}
          onChange={this.onCommentChange}
          placeholder='Write your comment here.'
          rows='6' />
          <button
            className='comment-button'
            onClick={this.submitComment}>Submit Comment
          </button>
        </div>
      </div>;
    } else {
      return <div>
        {comments}
        <div className='day-content-subsection bordered'>
          <div>
            Tell {operator.name} what you think of this day by posting a comment.
          </div>
          <button
            onClick={() => this.setEditing(true)}
            className='comment-button'>Comment
          </button>
        </div>
      </div>;
    }
  }
}

import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import "mapbox-gl/dist/mapbox-gl.css";
import React from "react";
import { getMapGLToken, getPOI } from "./Endpoints";

mapboxgl.accessToken = getMapGLToken();

export default class Map extends React.Component {

  constructor(props) {
    super(props);
    this.mapContainer = React.createRef();
  }

  async componentDidMount() {
    const { tour } = this.props;
    let route = tour.route;

    let longCoords = [];
    let latCoords = [];
    let pois = [];
    for (let stop of route) {
      let poi = await getPOI(`${stop.city},${stop.country}`);
      let poiCoordinates = poi.features[0].center;
      pois.push(poiCoordinates);
      longCoords.push(poiCoordinates[0]);
      latCoords.push(poiCoordinates[1]);
    }

    const map = new mapboxgl.Map({
      container: this.mapContainer.current,
      style: "mapbox://styles/uleben/ckqnluhvb0h6q17vvf5gt7ygs",
      center: [Math.min(...longCoords), Math.min(...latCoords)],
      zoom: 9,
      interactive: false
    });

    map.fitBounds([
      [Math.min(...longCoords), Math.min(...latCoords)], // southwestern corner of the bounds = min first, min second
      [Math.max(...longCoords), Math.max(...latCoords)] // northeastern corner of the bounds = max first, max second
    ], { padding: 50 });
    for (let i = 0; i < pois.length; i++) {
      let poi = pois[i];
      let el = document.createElement("div");
      el.className = "marker marker-map";
      el.innerText = route[i].indexChar;

      new mapboxgl.Marker(el)
        .setLngLat(poi)
        .addTo(map);
    }

  }

  render() {
    return <div ref={this.mapContainer} className='map-container' />;
  }
}


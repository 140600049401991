import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import "core-js";
import 'core-js/es/set'
import 'core-js/es/map'


import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Entry from "./Entry";

ReactDOM.render(
  <React.StrictMode>
    <Entry />
  </React.StrictMode>,
  document.getElementById('root')
);

import React from "react";

export default class SingleComment extends React.Component {

  render() {
    let { client, operator } = this.props;
    let { author, comment } = this.props.comment;
    let displayAuthor = author === "Client" ? client : operator;
    return <div className='comment-display bordered'>
      <div>{comment}</div>
      <div className='comment-author'>{displayAuthor}</div>
    </div>;

  }
}

import React from "react";
import gctLogo from "./assets/gct_logo.png";
import loader from "./assets/loader.gif";
import { getTour } from "./Endpoints";
import Tour from "./Tour";

// TODO Username
// TODO Cookies checkbox
// TODO Tour Error
export default class Entry extends React.Component {

  constructor(props) {
    super(props);
    let tourId = window.location.pathname.replaceAll("/", "");
    let isOperator = window.location.search === '?operator';
    this.state = {
      isOperator,
      tourId,
      loaded: false,
      tour: {}
    };
  }

  async componentDidMount() {
    const { tourId } = this.state;
    if (tourId !== "") {
      let tour = await getTour(tourId);
      this.setState({ tour, loaded: true });
    }
    this.setState({ loaded: true });

  }

  render() {
    const { tour, tourId, loaded, isOperator } = this.state;
    if (!loaded) {
      return <div className='loading'><img src={loader} alt='Loading...' />Loading tour...</div>;
    }
    if (tour && Object.keys(tour).length === 0 && tour.constructor === Object) {
      // Tour not found - show error text
      return <div className='entry-page'>
        <img className='entry-logo' src={gctLogo} alt='GrandcuveeTours logo' />
        <div className='entry-form'>
          <div className='error-text'>Tour with the ID you provided was not found.</div>
          <div className='error-text'>Please talk to your operator or contact us through our website</div>
          <div className='error-text'><a
            target='_blank' rel='noopener noreferrer' href='https://www.grandcuveetours.com/contact/'>www.grandcuveetours.com</a>
          </div>
        </div>
        <div className='footer-section'>Copyright © {new Date().getFullYear()} Grand Cuvée Tours, Najtura d.o.o.</div>
      </div>;
    }
    return <Tour tourId={tourId} isOperator={isOperator} />;
  }
}

const token = "pk.eyJ1IjoidWxlYmVuIiwiYSI6ImNrcW5scWVjdzFnaTQzMG54eGg3YmJ5dXoifQ.TX8i-LMa8oFlJUnbbnfPTw";
const awsURL = "https://najtura-images.s3.eu-central-1.amazonaws.com/";

export function getMapGLToken() {
  return token;
}

export function getImageURL(imageName) {
  return awsURL + imageName;
}

export async function getTour(id) {
  try {
    let response = await fetch(`/api/tour/${id}`);
    let responseJson = await response.json();
    return responseJson;
  } catch (err) {
    console.log(err);
    return {};
  }
}

export async function putOptionalService(dayId, tipId, selected) {
  try {
    let response = await fetch(`/api/day/${dayId}/tip/${tipId}/${selected}`, { method: "PUT" });
    if (response.ok) {
      return true;
    }
  } catch (err) {
    console.log(err);
    return false;
  }
}

export async function putDayComment(dayId, comment, author) {
  try {
    let response = await fetch(`/api/day/${dayId}/comment`,
      {
        method: "PUT",
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ comment, author })
      });
    if (response.ok) {
      return true;
    }
  } catch (err) {
    console.log(err);
    return false;
  }
}

export async function getPOI(query) {
  try {
    let response = await fetch(`https://api.mapbox.com/geocoding/v5/mapbox.places/${query}.json?access_token=${token}`);
    let responseJson = await response.json();
    return responseJson;
  } catch (err) {
    console.log(err);
    return {};
  }
}
